@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.header {
    margin-bottom: 2rem;
    border-bottom: .3rem solid $gray-2;
    text-align: center;
    &Author,
    &Title {
        font-weight: bold;
    }
    &Title {
        color: darken($primary, 5%)
    }
}

.headerSub {
    color: #7fb815;
    font-weight: bold;
    font-size: 24px;
}

@include breakpoint-up(xl) {
    .header {
        text-align: left;
    }
}
