@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.info {
    &Container {
        display: flex;
    }
    &Avatar {
        flex-basis: 6rem;
        max-width: 6rem;
        height: auto;
        margin-top: 3rem;
        &Img {
            max-width: 100%;
            height: auto;
            border-radius: 50%;
            border: 1px solid #fff;
        }
    }
    &Panel {
        background: $gray-2;
        padding: 2rem;
        border-radius: 2rem;
        position: relative;
        max-width: 50rem;
        margin-left: 1rem;
        flex: 1;
        &::before {
            content: '';
            width: 0;
            height: 0;
            border-left: 2.4rem solid transparent;
            border-bottom: 2.4rem solid $gray-2;
            border-right: 0rem solid transparent;
            position: absolute;
            top: 5rem;
            right: 100%;
        }
        &White {
            background: #fff;
            box-shadow: 0 0 .4rem rgba(0,0,0,.4);
            &::before {
                border-bottom-color: #fff;
            }
        }
    }
    &Title {
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        font-weight: bold;
        svg {
            margin-right: 1rem;
        }
    }
}

@include breakpoint-up(sm) {
    .info {
        &Avatar {
            flex-basis: 10rem;
            max-width: 10rem;
            margin-top: 0;
        }
    }
}
@include breakpoint-up(lg) {
    .info {
        &Avatar {
            flex-basis: 20rem;
            max-width: 20rem;
        }
        &Panel {
            margin-top: 3rem;
            margin-left: -1rem;
        }
    }
}
