.editor {
    .ce-block__content,
    .ce-toolbar__content {
        max-width: none;
    }
    .codex-editor {
        &__redactor {
            min-height: 24rem;
        }
        &--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before {
            font-style: italic;
        }
    }
}

// fix ct div top position in layout (before body tag closing)
.ct.ct--top:not(.ct--shown) {
    top: 0!important;
}

.tagvocab {
    background: #FFD653;
    padding: 1px 3px;
    border-radius: 2px;
    color: #fff;
}
.tagvocab .tagvocab {
    padding: 0;
}