
.menu-sidebar-book {
	flex-basis: 10rem;
	max-width: 10rem;
	margin-right: 1rem;
	margin-top: 2rem;
	
}
.bookmenu {
	&__link {
		display: block;
		text-decoration: none;
		color: $body-color;
		font-size: 2.4rem;
		padding: 1rem; 
		padding: .5rem 1rem;
		background: #fff;
		border-radius: 10rem;
		margin-bottom: 1rem;
		font-weight: bold;
		text-align: center;
		border: none;
		width: 100%;
		cursor: pointer;

		&:focus {
			outline: none;
		}

		&:hover {
			background: $primary;
			color: #fff;
		}

		&--active {
			border-radius: 10rem 0 0 10rem;
			padding-right: 2.6rem;
			width: calc(100% + 1.6rem);

			&:hover {
				background: #fff;
				color: $body-color;
			}
		}
	}
}