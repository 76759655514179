.holder {
    align-items: center;
    border: none;
    cursor: pointer;
    display: flex !important;
    justify-content: center;
    flex: 1;
    padding: 0 .2rem;
    width: auto !important;
}
.dulled {
    opacity: 0.5;
    pointer-events: none;
}
.displayOnly {
    pointer-events: none;
}
