@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';


.guidance {
    border-bottom: 1px solid #CCC;
    margin-bottom: 20px;
    &InfoIcon {
        width: 3.6rem;
        height: 3.6rem;
        border-radius: 5rem;
        line-height: 3.6rem;
        text-align: center;
        display: inline-block;
        margin-right: 1rem;
        cursor: pointer;
        box-shadow: 0 0 .2rem .1rem rgba(0, 0, 0, .3);
        position: absolute;
        top: 5rem;
        right: 5.4rem;
        z-index: 2;
        svg {
            margin-top: .7rem;
        }
        &:hover {
            background: $primary;
        }
    }

    &Panel {
        position: relative;
        display: none;
        margin-bottom: 2rem;
    }

    &Title {
        margin-bottom: 2rem;
        svg {
            vertical-align: middle;
        }
    }

    &Content {
        max-height: 24rem;
        overflow-y: auto;
    }

    &Feedback {
        .guidanceInfoIcon {
            background: $secondary;
        }
        .guidancePanel {
            background: $secondary;
            &::before {
                border-bottom: 2.4rem solid $secondary;
            }
        }
    }

    &Avatar {
        right: 0;
        top: 0;
        width: 8rem;
        height: 8rem;
        margin-left: auto;
        margin-bottom: 1rem;
        position: absolute;
        &Img {
            width: 100%;
            height: auto;
            border-radius: 50%;
            border: 1px solid #fff;
        }
    }
}

.guidanceMobile {
    position: fixed;
    background: linear-gradient($primary-dark, $primary) no-repeat fixed;
    z-index: 101;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    &Inner {
        background: #F4CB3E;
        min-height: calc(100% - 1rem);
        width: calc(100% - 1.5rem);
        margin: 0.5rem 0 0 1.5rem;
        border-radius: 2.4rem 0 0 2.4rem;
        padding: 1.5rem;
    }

    &Close {
        width: 5.8rem;
        height: 4.8rem;
        background: #fff;
        border-radius: 0 10rem 10rem 0;
        text-align: center;
        padding-top: 1rem;
        position: absolute;
        left: 1.5rem;
        top: 5rem;
        cursor: pointer;
        &:hover {
            color: $primary;
        }
    }

    &AvatarHolder {
        display: flex;
        justify-content: flex-end;
    }

    &Avatar {
        flex-basis: 14rem;
        max-width: 14rem;
        &Img {
            width: 100%;
            height: auto;
            border-radius: 50%;
            border: 1px solid #fff;
        }
    }

    &Panel {
        display: block;
        background: #fff;
        margin-top: 1rem;
        &::before {
            border-bottom: 2.4rem solid #fff;
            right: 8rem;
        }
    }
}

.taskFileView {
    align-items: center;
    background: #FFF;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    border-radius: 7px;
    color: #4a4a4a;
    padding: 4px;
    margin-bottom: 5px;
}

.taskFileView:hover .taskFileName {
    color: #9DC030;
}

.taskFileEye {
    margin-left: auto;
}


@include breakpoint-up(md) {
    .guidance {
        &Panel {
            display: block;
            &::before {
                right: 4.5rem;
            }
        }

        &InfoIcon {
            display: none;
        }
        &Avatar {
            position: static;
        }
    }
}

@include breakpoint-up(lg) {
    .guidance {
        position: relative;
        &Panel {
            margin-right: 130px;
            &::before {
                border: none;
                right: -2.4rem;
                top: 0;
                border-right: 2.4rem solid transparent;
                border-top: 2.4rem solid transparent;
                border-bottom: 2.4rem solid $gray-2;
            }
        }
        &Avatar {
            right: 0;
            top: 0;
            width: 10rem;
            height: 10rem;
            position: absolute;
        }
    }
}