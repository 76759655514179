@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.book {

    &Header {
        display: flex;
        margin-bottom: 4rem;
        &Img {
            align-items: center;
            display: flex;
            max-width: 10rem;
            margin-right: 1.5rem;
            overflow: hidden;
        }
        &Meta {
            display: flex;
            flex-direction: column;
            padding: .5rem 0;
        }
    }
    &Chapters {
        li {
            margin-bottom: .8rem;
        }
        &Link {
            background: none;
            border-radius: .5rem;
            border: 1px solid $gray-1;
            display: block;
            width: 100%;
            text-align: left;
            text-transform: none;
            &:hover {
                color: #fff;
                background: $primary;
                border-color: $primary;
            }
        }
    }
}

.bookCover {
    min-height: 40rem;
    margin: 0 auto;
    img {
        max-width: 100%;
        height: auto;
        margin: auto;
    }
}

@include breakpoint-up(md) {
 
    .bookCover {
        margin-top: 10rem;
    }
}


@include breakpoint-up(lg) {
    .book {
        &Header {
            &Img {
                max-width: 16rem;
            }
        }
    }
}
