
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  min-height: 100%;
}

body {
    font-family: $body-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    text-align: left;
    color: $body-color;
    position: relative;
    background: url('../../images/pattern-blue.png');
    @include breakpoint-up(md) {
        font-size: $base-desktop-font-size;
    }
}


.app-container {
    display: flex;
    min-height: 100vh;
    &__content {
        padding: 1.5rem 1.5rem 12rem 1.5rem;
        flex: 0 0 100%;
        min-height: 100%;
        display: flex;
        @include breakpoint-up(sm) {
            padding: 2.4rem 2.4rem 2.4rem 13rem;
        }
    }
}


// router extra div fix
.app-container__content >div {
    flex: 0 0 100%;
    min-height: 100%;
    display: flex;
}

.content-panel  {
    flex: 1;
}


.book-wrapper {
    display: flex;
    flex-grow: 1;
}