@import '../../styles/variables.scss';

.tabs {
    display: flex;
    margin-bottom: 2rem;
    border-bottom: solid .1rem $gray-2;
    &NoBorder {
        border-bottom: none;
    }
}
