@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.chapter {
    display: flex;
    flex-direction: column;
    &Entry {
        background: #FFF;
        padding: 10px;
        border-radius: 20px;
        margin-right: 20px;
        &Content {
            margin-bottom: 1.5rem;
            border-radius: 1.6rem;
            padding: 2rem;
            border: 1px solid $gray-1;
        }
        &SaveActions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
        }
        &Tabs {
            margin-top: 2rem;
        }
    }

    &WordsWarning,
    &WordsDanger {
        background: #FFD653;
        text-align: center;
        border-radius: 4rem;
        padding: .4rem 1rem;
    }
}


@include breakpoint-up(md) {
    .chapter {
        display: flex;
        flex-direction: row;
        &Entry {
            flex-basis: 65%;
            max-width: 65%;
            padding: 40px;
            &SaveActions {
                flex-direction: column;
                display: block;
                li:first-child {
                    margin-bottom: 1rem;
                }
            }
            &Content {
                margin-right: 3rem;
                margin-bottom: 0;
            }
        }
        &Side {
            background: #FFF;
            padding: 40px;
            border-radius: 20px;
            flex-basis: 35%;
            max-width: 35%;
            order: 0;
            display: flex;
            flex-direction: column;
            &Actions {
                margin-top: auto;
                button {
                    display: block;
                    width: 100%;
                }
            }
            &Sticky {
                position: sticky;
                top: 3rem;
            }
        }
    }
}

.writingClosed {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    &Icon {
        color: $primary-dark;
    }
    &Text {
        font-size: 3rem;
    }
}

.mainContainer {
    display: flex;
    flex-direction: column;
}

.upper {
    display: flex;
    margin-bottom: 20px;
    justify-content: right;
}

.closeBtn {
    background: #FFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    color: #4a4a4a;
}
.closeBtnTxt {
    margin-right: 5px;
    display: block;
}