
$mobile-landscape: 480px;

.modal {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 100;

    &Blanker {
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        height: 100%;
        justify-content: center;
        overflow: scroll;
        width: 100%;
    }

    &BoomerWrapper {
        right: 0;
        top: 0;
        position: fixed;
        bottom: 0;
        left: 0;
        max-height: calc(100vh - 40px);
        max-width: 500px;
        width: 100%;
        overflow: initial;
        position: relative;
        padding-top: 180px;
        background: url('../../../../images/storybook-boomer.png') no-repeat center top;
        background-size: 50%;
        margin: 20px;
    }

    &Content {
        background: white;
        overflow: auto;
        padding: 30px;
        border-radius: 1rem;
    }

}

@media screen and (min-width: $mobile-landscape) {
    .modal {
        &Content {
            &Inner {
                max-height: calc(100vh - 100px);
                overflow: auto;
            }
        }
    }
}