.task {
    padding: 2rem 1rem;
    margin-bottom: 1rem;
    border-radius: 1rem;
    color: #fff;
    background: #4E406E;
    display: flex;
    font-weight: bold;
    h2 {
        margin-bottom: 0;
        font-weight: bold;
        font-size: 20px;
    }
    &Main {
        flex: 1;
    }
    &Side {
        flex-basis: 15rem;
        max-width: 15rem;
        flex-shrink: 0;
        text-align: center;
    }
    &Poll {
        background: #c53464;
    }
}
.iconWrite,
.iconVote  {
    background: url('../../../images/writeIcon.png') no-repeat top left;
    padding-left: 26px;
    background-size: 20px;
    min-height: 20px;
    display: inline-block;
}
.iconVote {
    background: url('../../../images/voteIcon.png') no-repeat top left;
        background-size: 20px;
}