%card-base {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: .5rem;
  flex: 1 0 0;
}
.card {
    @extend %card-base;
    border:1px solid $gray-1;
    &.card-hover {
    color: $body-color;
    transition: all .2s ease-in;
    margin-bottom: 2rem;
        &:hover {
          border-color: rgba(0, 0, 0, 0.2);
          box-shadow: 0 0.3rem .5rem 0 rgba(0, 0, 0, 0.12);
        }
    }
    .card-link {
        color: $body-color;
    }

    .card-header {
        padding: 1.5rem;

    }
    .card-body {
        flex: 1 1 auto;
        padding: 1.5rem;
    }
}

@include breakpoint-up(md) {
  .card {
        .card-body,
        .card-header {
            padding: 2rem;
        }
    }
}
