.mainContainer {
    display: flex;
    flex-direction: column;
}

.upper {
    display: flex;
    margin-bottom: 20px;
    justify-content: right;
}

.closeBtn {
    background: #FFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    color: #4a4a4a;
}
.closeBtnTxt {
    margin-right: 5px;
    display: block;
}
.container {
    width: 100%;
    display: flex;
}

.pollHeader {
    background: #FFF;
    padding: 30px;
    border-radius: 20px;
    width: 100%;
}

.pollHeading {
    margin-bottom: 0;
}

.pollOptions {
    display: flex;
    margin: 40px -10px 0 -10px;
}

.pollOption {
    flex: 1;
    margin: 0 10px;
    background: #FFF;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 10px solid #FFF;
    &Selected {
        border: 10px solid #7fbf15;
    }
    &:hover {
        background: #FaFaFA;
    }
    &Fade {
        opacity: 0.35
    }
    &Chosen {
        opacity: 1
    }
}

.reactionImg {
    max-height: 150px;
}

.pollSubHeading {
    color: #FFF;
    margin-top: 40px;
}

.pollTextArea {
    width: 100%;
    border: none;
    border-radius: 20px;
    padding: 20px;
}

.questionPara {
    color: #FFF;
    font-size: 16;
}

.main {
    flex-basis: 65%
}
.aside {
    flex-basis: 35%;
    padding-left: 20px;
}
.asideInner {
    background: #FFF;
    border-radius: 20px;
    padding: 20px;
    padding-right: 130px;
    position: relative;
}
.instructions {
    display: flex;
    align-items: center;
}
.instructionsBoomer {
    position: absolute;
    right: 10px;
    width: 120px;
    height: auto;
    top: 5px;
}