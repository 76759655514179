@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.header {
    margin-bottom: 4rem;
    position: relative;
    &Top {
        display: flex;
        align-items: center;
        min-height: 1px;
        &Title {
            flex: 1;
            cursor: pointer;
            padding-right: 13rem;
            margin-bottom: 0;
            display: none;
        }
        &Avatar {
            position: absolute;
            top: -6.5rem;
            right: -2rem;
            width: 6.8rem;
            height: auto;
            &Img {
                max-width: 100%;
                height: auto;
                position: relative;
                display: block;
                border-radius: 50%;
                border: 1px solid #fff;
            }
            &ImgStack {
                z-index: 2;
            }
            &InfoIcon {
                @include circleIcon(3rem, $gray-2);
                position: absolute;
                top: 4rem;
                z-index: 3;
                box-shadow:  0 0 .4rem rgba(0,0,0,.2);
            }
        }
        &Stars {
            position: absolute;
            top: -1rem;
            right: 0rem;
            z-index: 1;
            min-width: 16rem;
        }
    }
    &InfoIcon {
        @include circleIcon(3.8rem, $gray-2);
        box-shadow: 0 0 .2rem .1rem rgba(0, 0, 0, .3);
        &:hover {
            background: $primary;
        }
    }
    &Panel {
        position: absolute;
        top: 100%;
        max-width: 60rem;
        min-width: 30rem;
        border-radius: 1rem;
        padding: 1.5rem;
        background: #fff;
        z-index: 2;
        box-shadow: 0 0 .2rem .1rem rgba(0, 0, 0, .3);
        &Content {
            display: flex;
            align-items: flex-start;
            padding-right: 7rem;
            position: relative;
            // img {
            //     max-width: 10rem;
            //     margin-right: 1.5rem;
            //     border-radius: 2rem;
            // }
        }
        &ImageHolder {
            align-items: center;
            display: flex;
            max-width: 8rem;
            margin-right: 1.5rem;
            overflow: hidden;
        }
        &Close {
            position: absolute;
            right: -1.5rem;
            top: 0rem;
            padding: .5rem 1rem;
            height: 4.6rem;
            border-radius: 3rem 0 0 3rem;
            background: $primary;
            cursor: pointer;
            &:hover {
                color: #fff;
            }
        }
    }
}


@include breakpoint-up(sm) {
    .header {
        margin-bottom: 6rem;

        &Top {
            &Title {
                display: block;
            }
            &Avatar {
                top: -4.5rem;
                right: -4.5rem;
                width: 12rem;
                &InfoIcon {
                    @include circleIcon(4rem, $gray-2);
                    top: 8rem;
                }
            }
            &Stars {
                top: 0rem;
                min-width: 18rem;
            }
        }
    }
}

@include breakpoint-up(md) {
    .header {
        &Top {
            &Avatar {
                top: -4.5rem;
                right: -4.5rem;
                width: 12rem;
            }
        }
    }
}


@include breakpoint-up(lg) {
    .header {
        &Top {
            &Avatar {
                top: -4.5rem;
                right: -4.5rem;
                width: 14rem;
            }
            &Stars {
                top: 0;
                min-width: 22rem;
            }
        }
    }
}
@include breakpoint-up(xl) {
    .header {
        &Top {
            &Avatar {
                top: -8.5rem;
                right: -6.5rem;
                width: 18rem;
                &InfoIcon {
                    @include circleIcon(6rem, $gray-2);
                    top: 12rem;
                }
            }
            &Stars {
                top: 3rem;
                min-width: 16rem;
            }
        }
    }
}
