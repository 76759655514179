@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.votingPanel {
    position: fixed;
    z-index: 99;
    background-image: linear-gradient(to right, #2299AC , #0F6074);
    box-shadow: 0 0 .5rem rgba(0,0,0, 0.3);
    left: 0;
    right: 0;
    top: 6.5rem;
    bottom: 0;
    border-radius: 1rem 0 0 1rem;
    padding: 2rem;
    overflow-y: auto;
    &Header {
        text-align: right;
    }
    &Close {
        @include circleIcon(4rem, #fff);
        box-shadow: 0 .1rem 0.4rem rgba(0,0,0, 0.3);
    }
    &Content {
        margin-top: 6rem;
    }
    &Stars {
        position: absolute;
        top: -4rem;
        right: -2rem;
        max-width: 12rem;
    }
}


@include breakpoint-up(sm) {
    .votingPanel {
        top: 0;
        left: 10rem;
        &Close {
            @include circleIcon(7rem, #fff);
        }
        &Stars {
            top: -4rem;
            right: 0rem;
            max-width: 16rem;
        }
    }

}

@include breakpoint-up(md) {
    .votingPanel {
        &Stars {
            top: -5rem;
            right: 2rem;
            max-width: 18rem;

        }
    }
}

@include breakpoint-up(lg) {
    .votingPanel {
        left: 15rem;
        &Stars {
            top: -8rem;
            right: 2rem;
            max-width: none;
        }
    }
}

@include breakpoint-up(xl) {
    .votingPanel {
        left: 20rem;
    }
}
