.title {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    &Disabled {
        opacity: 0.5
    }
}
.ratingText {
    font-size: 2.4rem;
    font-weight: bold;
    text-align: center;
    margin: 1.5rem 0;
    &Disabled {
        opacity: 0
    }
}
